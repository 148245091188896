import React from "react"
import imgTeam from "../assets/images/organigrama.png"
import Section from "./section"

const Team = () => {
  return (
    <Section>
      <div className="flex flex-col-reverse md:flex-row-reverse gap-16 justify-center items-center w-full max-w-5xl">
        <div className="order-1 md:w-1/2 flex flex-col gap-6 items-center">
          <h1 className="text-6xl font-bold font-ubuntu text-left w-full">
            Cuadro directivo
          </h1>
          <h2 className="text-xl text-left w-full font-ubuntu">
            Conformado por socios y colaboradores con experiencia en el sector
          </h2>
        </div>
        <img
          className="w-4/5 md:w-1/2 border rounded-lg shadow-lg bg-black"
          src={imgTeam}
          alt="SVG as an image"
        />
      </div>
    </Section>
  )
}

export default Team
