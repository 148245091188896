import React from "react"
import Section from "./section"

const Institutional = () => {
  return (
    <Section>
      <div className="flex flex-col gap-12 justify-center items-center">
        <h1 className="w-full text-center text-4xl font-ubuntu">
          ¿Quiénes somos?
        </h1>
        <div className="flex flex-col justify-center w-2/3 bg-slate-100 py-8 px-4 rounded-lg border-blue-800 shadow-md">
          <div>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Institutional
