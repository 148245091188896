import React from "react"
import TopBanner from "../components/top-banner"
import Header from "../components/header/header"
import Team from "../components/team"
import Institutional from "../components/institutional"
import Footer from "../components/footer"

const About = () => {
  return (
    <div className="flex flex-col bg-gradient-to-t from-sky-50">
      <TopBanner />
      <Header />
      <Institutional />
      <Team />
      <Footer />
    </div>
  )
}

export default About
